














































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class CustomVehicleList extends Vue {
  vehicles = []

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: -1,
    sortBy: [],
    totalItems: 0,
  }

  get headers() {
    return [
      {
        text: this.$t('Brand'),
        value: 'brand',
      },
      {
        text: this.$t('Model'),
        value: 'name',
      },
      {
        text: this.$t('Variant'),
        value: 'name',
      },
      {
        text: this.$t('Generation'),
        value: 'name',
        sortable: false,
      },
      {
        text: this.$t('Vehicle connections'),
        value: 'variants',
      },
      {
        text: this.$t('Rims'),
        value: 'rims',
      },
      {
        text: this.$t('Tyres'),
        value: 'tyres',
      },
      {
        text: this.$t('Wheels'),
        value: 'wheels',
      },
      {
        text: this.$t('Internal comment'),
        value: 'comment',
      },
      {
        text: this.$t('External comment'),
        value: 'externalComment',
      },
      {
        text: this.$t('Active'),
        value: 'inactive',
      },
      {
        text: this.$t('Actions'),
        sortable: false,
        value: 'name',
      },
    ]
  }

  mounted() {
    this.$axios.get('/v3/custom-vehicles').then((response) => {
      this.vehicles = response.data.data
    })
  }

  deleteVehicle(id) {
    if (confirm(this.$t('Are you sure you want to delete this item?').toString())) {
      this.$axios.delete('/v3/custom-vehicles/' + id).then((response) => {
        let index = null
        this.vehicles.forEach(function (vehicle, i) {
          if (vehicle._id === id) {
            index = i
          }
        })
        if (index !== null) {
          this.vehicles.splice(index, 1)
        }
      })
    }
  }

  getBrandName(id) {
    for (let i = 0; i < vxm.vehicles.brands.length; i++) {
      const brand = vxm.vehicles.brands[i]
      if (brand.id === id) {
        return brand.name
      }
    }
  }
}
